@import 'fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    height: 100vh;
  }
  @supports (-webkit-touch-callout: none) {
    body {
      height: -webkit-fill-available;
    }
  }
  a, button {
    @apply focus:outline-none focus-visible:ring focus-visible:ring-yellow-200 rounded;
  }
  [hidden] {
    display: none;
  }
}

.\@tooltip-triangle {
  border-width: 0 6px 6px;
  border-color: transparent;
  border-bottom-color: white;
  position: absolute;
  bottom: -6px;
  transform: rotate(180deg);
  left: 50%;
}

hr.\@grid-separator {
  border: 0;
  display: block;
  left: 50%;
  background-color: currentColor;
  opacity: 0.1;
  margin: 2px 1rem;
  width: 1px;
  height: auto;
}
