@font-face {
  font-family: "Blanco";
  src: url("fonts/Blanco-Regular.woff2") format("woff2"),
    url("fonts/Blanco-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Blanco";
  src: url("fonts/Blanco-Italic.woff2") format("woff2"),
    url("fonts/Blanco-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Blanco";
  src: url("fonts/Blanco-Bold.woff2") format("woff2"),
    url("fonts/Blanco-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Blanco";
  src: url("fonts/Blanco-BoldItalic.woff2") format("woff2"),
    url("fonts/Blanco-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}
